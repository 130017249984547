<template>
  <div>
    <h1 class="mb-0">Recuperar Contraseña</h1>
    <p>
      Ingrese el correo electrónico asociado a su cuenta y siga las
      instrucciones para recuperar su contraseña.
    </p>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)" class="mt-4">
        <ValidationProvider
          name="Correo"
          rules="email|required"
          v-slot="{ errors }"
        >
          <b-form-input
            v-model="form.email"
            type="text"
            placeholder="Correo de Recuperación"
            :class="errors.length > 0 ? ' is-invalid' : ''"
          ></b-form-input>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <div class="d-inline-block w-100 mt-4">
          <router-link to="/auth/login" class="btn btn-link">
            Iniciar Sesión
          </router-link>
          <button type="submit" class="btn btn-primary float-right">
            Reestablecer Contraseña
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import csrf from '@/apis/Csrf'
import axios from 'axios'
import { core } from '@/config/pluginInit'
export default {
  name: 'RecoverPassword1',
  data () {
    return {
      form: {
        email: ''
      },
      spinner: false
    }
  },
  methods: {
    async onSubmit () {
      try {
        this.$store.commit('run')
        await csrf.getCookie()
        await axios.post('/api/password/email', this.form)
        this.$router.push('/auth/login')
        this.$store.commit('stop')
        core.showSnackbar('success', 'Solicitud Enviada Correctamente')
      } catch (error) {
        this.$store.commit('stop')
        const errors = error.response.data.errors
        for (const key in errors) {
          core.showSnackbar('error', errors[key][0])
          return false
        }
      }
    }
  }
}
</script>
